import useSWR from "swr";
import axios from "../libs/axios";

import { useEffect } from "react";
import { useRouter } from "next/router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { USER_BASIC_INFO_URL } from "@/common/utils/constant";

const csrf = () => axios.get("/mt/csrf-cookie");

export const userFeedback = async ({ setErrors, setSuccessMessage, ...props }) => {
  await csrf();

  setErrors([]);
  setSuccessMessage(""); // Clear previous success messages

  try {
    const response = await axios.post("/api/feedback", props);

    if (response.data.status === 200) {
      setSuccessMessage(response.data.message);
      // Optionally, you can perform additional actions upon successful submission
    } else {
      console.error("Unexpected response status:", response.data.status);
      // Handle unexpected response status
    }
  } catch (error) {
    if (error.response && error?.response?.status !== 422) {
      console.error("API error:", error);
      // Handle other API errors
    } else if (error.response && error?.response?.status === 422) {
      setErrors(Object.values(error.response.data.errors).flat());
    } else {
      console.error("Unexpected error:", error);
      // Handle unexpected errors
    }
  }
};


export const useAuth = ({
  middleware,
  userRedirectIfAuthenticated,
  adminRedirectIfAuthenticated,
} = {}) => {
  const router = useRouter();
  const MySwal = withReactContent(Swal);
  const {
    data: user,
    error,
    mutate,
  } = useSWR(
    "/api/user",
    () =>
      axios
        .get("/api/user")
        .then((res) => res.data)
        .catch((error) => {
          if (error?.response?.status === 450) {
            // Email not verified
            router.push("/check-email");
          } else {
            throw error; // Handle other errors normally
          }
        }),
    {
      shouldRetryOnError: false,
      refreshInterval: 0, // Disable polling
    }

  );

  // User Apis

  const getUser = async ({ setErrors }) => {
    try {
      const res = await axios.get("/api/user");
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  const fetchtranslation = async ({
    setErrors,
    setLimitExceed,
    setLimitExceedType,
    setLimitExceedMsg,
    ...props
  }) => {
    await csrf();

    setErrors([]);
    axios
      .post("/api/translate", { token: router.query.token, ...props })
      .then((response) => {
        props.setLoader(false);
        if (response.data.status == 402) {
          setLimitExceed(true);
          setLimitExceedType(response.data.type);
          setLimitExceedMsg(response.data.message);
        } else {
          var translates = JSON.parse(response.data.data);
          props.setTranslations(translates);
          var dictionary = JSON.parse(response.data.dictionary);
          props.setDictionary(dictionary);
          setLimitExceed(false);
          setLimitExceedType(0);
        }
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;
        props.setLoader(false);
        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const fetchdictionary = async ({ setErrors, ...props }) => {
    await csrf();

    setErrors([]);

    axios
      .post("/api/dictionary", { token: router.query.token, ...props })
      .then((response) => {
        var dictionary = JSON.parse(response.data.dictionary);
        props.setDictionary(dictionary);
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const getfilecount = async ({ docfile, setErrors }) => {
    await csrf();
    setErrors([]);
    let data = new FormData();
    data.append("file", docfile);
    try {
      const res = await axios.post("/api/count-words", data, {
        token: router.query.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      if (error.response && error?.response?.status != 422) throw error;
      if (error.response && error.response.data) {
        setErrors(Object.values(error.response.data.errors).flat());
      } else {
        setErrors(Object.values(["error", "something went wrong"]).flat());
      }
    }
  };
  const documentTranslation = async ({ setErrors, docfile, model, frontendTabs }) => {
    await csrf();
    setErrors([]);
    let data = new FormData();
    data.append("file", docfile);
    data.append("model", model);
    data.append("frontendModel", frontendTabs);
    try {
      const res = await axios.post("/api/doc/translate", data, {
        token: router.query.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const docTranslationHistory = async ({ setErrors }) => {
    setErrors([]);

    try {
      const res = await axios.get("/api/user/translation-history");
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const getTranslatedFileUrl = async ({ setErrors, project_id }) => {
    setErrors([]);

    try {
      const res = await axios.get("/api/translated-file-url/", {
        params: {
          id: project_id
        },
        responseType: 'blob',

      });
      return res;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const deleteTranslatedDocument = async ({ setErrors, project_id, ...props }) => {
    setErrors([]);
    let data = new FormData();
    data.append("token", props.token);
    data.append("project_id", project_id)
    try {
      const res = await axios.post(
        "/api/user/translation-history/delete",
        data,
        // {
        //   token: router.query.token,
        // }
      );
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const deleteAllTranslatedDocument = async ({ setErrors }) => {
    setErrors([]);
    try {
      const res = await axios.post("/api/user/translation-history/delete/all", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  const transaction3d = async ({ setErrors }) => {
    await csrf();
    setErrors([]);
    try {
      const res = await axios.post("/api/test3d", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  const checkoutauth = async ({ id, setErrors }) => {
    await csrf();
    let data = new FormData();
    data.append("session_id", id);
    try {
      const res = await axios.post("/api/checkout-auth", data, {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  const hyperpayAuth = async ({ id, path, setErrors }) => {
    await csrf();
    let data = new FormData();
    data.append("id", id);
    data.append("path", id);

    try {
      const res = await axios.post("/api/hyperpay-auth", data, {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  const downloadTranslatedFile = async ({ setErrors, ...props }) => {
    let projectId = props.projectId;
    setErrors([]);
    try {
      const res = await axios.get("/api/doc/translated/" + projectId);
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const downloadUserInvoice = async ({ setErrors, ...props }) => {
    let invoice_id = props.invoice_id;
    setErrors([]);
    try {
      const res = await axios.get(
        "/api/user/invoices/" + invoice_id + "/download"
      );
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const userReaction = async ({ ...props }) => {
    let reaction = props.reaction;
    let translationId = props.translationId;

    try {
      const res = await axios.get(
        "/api/reaction/" + translationId + "/" + reaction
      );
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const suggestedTranslation = async ({ setErrors, ...props }) => {
    await csrf();
    setErrors([]);
    let data = new FormData();
    data.append("suggestedTranslation", props.sTranslation);
    data.append("translationId", props.translationId);
    try {
      const res = await axios.post("/api/user/suggested-translation", data, {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const getplans = async () => {
    try {
      const res = await axios.get("/api/plans", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const getplandetail = async ({ ...props }) => {
    axios
      .get(`/api/user/plans/${props.id}`, {
        token: router.query.token,
        ...props,
      })
      .then((response) => {
        var plan = response.data;
        props.setPlan(plan);
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;
      });
  };

  // const register = async ({ setErrors, ...props }) => {
  //     await csrf();

  //     setErrors([]);

  //     try {
  //         await axios.post("/mt/register", props);
  //         mutate();
  //     } catch (error) {
  //         if (error?.response?.status !== 422) throw error;

  //         setErrors(Object.values(error.response.data.errors).flat());
  //     }
  // };

  const register = async ({ setErrors, ...props }) => {
    await csrf();
  
    setErrors([]);
  
    try {
      const response = await axios.post("/mt/register", props); // Make sure to capture the response here
      mutate(); // You may still want to call this
      return response; // Return the response to the caller
    } catch (error) {
      if (error?.response?.status !== 422) throw error;
  
      setErrors(Object.values(error.response.data.errors).flat());
      return null; // Return null in case of error
    }
  };
  



  const updateUser = async ({ setErrors, ...props }) => {
    await csrf();

    setErrors([]);
    axios
      .post("/api/user/update", { token: router.query.token, ...props })
      .then(() => {
        mutate();
      })
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const socialAuthLogin = async ({ setErrors, ...props }) => {
    setErrors([]);
    let type = props.type;
    try {
      const res = await axios.get("/mt/login/" + type);
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const paymentdetails = async ({ setErrors, ...props }) => {
    await csrf();

    setErrors([]);
    axios
      .post("/api/user/subscribe", props)
      .then((response) => {
        if (response.data.status == 200) {
          MySwal.fire({
            title: response.data.message,
            confirmButtonText: "Ok",
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              router.replace(USER_BASIC_INFO_URL);
            }
          });
        } else if (response.data.status == 201) {
          MySwal.fire({
            title: response.data.message,
            confirmButtonText: "Ok",
            icon: "error",
          });
        } else {
          MySwal.fire({
            title: response.data.error_codes[0],
            confirmButtonText: "Ok",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const login = async ({ setErrors, setStatus, setLoading, ...props }) => {
    await csrf();
    setErrors([]);
    setStatus(null);
    setLoading(true);
    axios
      .post("/mt/login", props)
      .then((res) => {
        mutate();
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;
        setErrors(Object.values(error.response.data.errors).flat());
        setLoading(false);
      });
  };

  //feedback

  //   const userFeedback = async ({ setErrors, ...props }) => {
  //   await csrf();

  //   setErrors([]);

  //   axios
  //     .post("/mt/feedback", props)
  //     .then(() => {
  //       mutate();
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status !== 422) throw error;

  //       setErrors(Object.values(error.response.data.errors).flat());
  //     });
  // };
  
  const forgotPassword = async ({ setErrors, setStatus, setLoader, email }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post("/mt/forgot-password", { email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        setLoader(false);
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const resetPassword = async ({ setErrors, ...props }) => {
    await csrf();

    try {
      const res = await axios.post("/mt/reset-password", {
        token: router.query.token,
        ...props,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  const acceptInvitation = async ({ setErrors, ...props }) => {
    await csrf();

    try {
      const res = await axios.post("/api/create-user", {
        token: router.query.token,
        ...props,
      });
      axios
        .post("/mt/login", props)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error?.response?.status !== 422) throw error;
          setErrors(Object.values(error.response.data.errors).flat());
        });
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };



  const resendEmailVerification = ({ setStatus }) => {
    axios
      .post("/email/verification-notification")
      .then((response) => setStatus(response.data.status));
  };

  const logout = async () => {
    if (!error) {
      await axios.post("/mt/logout").then(() => mutate());
    }

    window.location.pathname = "/";
  };

  const settings = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post("/api/user/settings", { token: router.query.token, ...props })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const autoDeleteSettings = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post("/api/user/auto-delete-settings", { token: router.query.token, ...props })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  

  const siteSettings = async () => {
    await csrf();

    try {
      const res = await axios.get("/api/site-settings", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
    }
  };

  const updateSiteSettings = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    axios
      .post("/api/admin/update-site-settings", props)
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const getHyperpayCheckout = async () => {
    try {
      const res = await axios.get("/api/hyperpay/checkout", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  }
  const usersubscription = async ({ ...props }) => {
    axios
      .get("/api/user/subscription", { token: router.query.token, ...props })
      .then((response) => {
        var subscription = response.data.data;
        props.setSubscription(subscription);
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;
      });
  };
  const authpasswordsettings = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post("/api/user/password-update", props)
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const disconnectSocial = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    axios
      .post("/api/user/disconnect", props)
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const deleteAccount = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    try {
      const res = await axios.post("/api/user/delete-account", {
        token: router.query.token,
        ...props,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const saveUserBilling = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    try {
      const res = await axios.post("/api/user/subscribe", props, {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const updateUserBilling = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    try {
      const res = await axios.post("/api/user/update-billing", props, {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const getCapturePendingInvoice = async ({ setErrors }) => {
    await csrf();

    setErrors([]);

    try {
      const res = await axios.post("/api/user/pending-invoice/capture", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const changeUserPlan = async ({ setErrors, ...props }) => {
    await csrf();
    setErrors([]);
    let data = new FormData();
    data.append("plan", props.plan);
    try {
      const res = await axios.post("/api/user/update-plan", data, {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const storeUserPlan = async ({ setErrors, ...props }) => {
    await csrf();

    setErrors([]);
    try {
      const res = await axios.post("/api/user/save-plan", props);
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const getCustomerInvoices = async ({ setErrors }) => {
    try {
      const res = await axios.get("/api/user/invoices", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  // Users Apis End
  // Admin apis
  const adminplans = async ({ ...props }) => {
    axios
      .get("/api/admin/plans", { token: router.query.token, ...props })
      .then((response) => {
        var plans = response.data;
        props.setPlans(plans);
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;
      });
  };
  const adminusers = async ({ ...props }) => {
    try {
      const res = await axios.get("/api/admin/users", {
        token: router.query.token,
      });
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };
  const updateAdminplan = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    axios
      .post("/api/admin/user-plan", props)
      .then((response) => {
        MySwal.fire({
          title: response.data.status,
          confirmButtonText: "Ok",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const storeplan = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    axios
      .post("/api/admin/plans", props)
      .then((response) => {
        MySwal.fire({
          title: response.data.status,
          confirmButtonText: "Ok",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            router.replace("/admin/plans");
          }
        });
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const updateplan = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);
    axios
      .post(`/api/admin/plans/update/${props.planid}`, props)
      .then((response) => {
        MySwal.fire({
          title: response.data.status,
          confirmButtonText: "Ok",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push("/admin/plans");
          }
        });
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;

        setErrors(Object.values(error.response.data.errors).flat());
      });
  };
  const deleteplan = async ({ ...props }) => {
    await csrf();

    axios
      .post(`/api/admin/plans/delete/${props.id}`, props)
      .then((response) => {
        MySwal.fire({
          title: response.data.status,
          confirmButtonText: "Ok",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            router.push("/admin/plans");
          }
        });
      })
      .catch((error) => {
        if (error?.response?.status != 422) throw error;
      });
  };
  const addTrialDays = async ({ ...props }) => {
    await csrf();

    try {
      const res = await axios.post(`/api/admin/user/add-trial-days`, props);
      return res.data;
    } catch (error) {
      if (error?.response?.status != 422) throw error;
      setErrors(Object.values(error.response.data.errors).flat());
    }
  };

  // Admin Apis End
  useEffect(() => {
    if (middleware === "guest" && user) {
      if (user["role_id"] == "1" && adminRedirectIfAuthenticated) {
        router.push(adminRedirectIfAuthenticated);
      } else if (user["role_id"] == "2" && userRedirectIfAuthenticated) {
        router.push(userRedirectIfAuthenticated);
      }
    }
    if (middleware === "auth" && error) logout();
  }, [user, error]);

  return {
    user,
    // subscription
    paymentdetails,
    settings,
    autoDeleteSettings,
    usersubscription,
    getHyperpayCheckout,
    storeUserPlan,
    getUser,
    transaction3d,
    checkoutauth,
    hyperpayAuth,
    changeUserPlan,
    saveUserBilling,
    updateUserBilling,
    getCapturePendingInvoice,
    getCustomerInvoices,
    authpasswordsettings,
    register,
    updateUser,
    fetchtranslation,
    fetchdictionary,
    getfilecount,
    documentTranslation,
    docTranslationHistory,
    getTranslatedFileUrl,
    deleteTranslatedDocument,
    deleteAllTranslatedDocument,
    downloadTranslatedFile,
    downloadUserInvoice,
    suggestedTranslation,
    userReaction,
    login,
    socialAuthLogin,
    disconnectSocial,
    deleteAccount,
    forgotPassword,
    resetPassword,
    acceptInvitation,
    resendEmailVerification,
    getplans,
    getplandetail,
    logout,
    userFeedback,

    // admin
    adminplans,
    updateAdminplan,
    adminusers,
    siteSettings,
    updateSiteSettings,
    storeplan,
    updateplan,
    deleteplan,
    addTrialDays,
  };
};
